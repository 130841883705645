// AuctionVisualization.jsx
import React, { useState, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { useConnect, useSwitchChain } from "wagmi";

const AuctionVisualization = ({
  proposal,
  bidsData,
  loadingBids,
  onBid,
  conversionRate,
  minBid,
  isConnected,
  currentChainId,
  proposalNetwork,
  txStatus,
  txHash,
  errorMessage,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [inputError, setInputError] = useState("");
  const { connectors, connect, isLoading: isConnecting } = useConnect();
  const { switchChainAsync } = useSwitchChain();

  // Format options from proposal
  const options = useMemo(() => {
    if (!proposal || !proposal.options || !proposal.options_text) {
      return [];
    }

    // Map options to a more usable format
    return proposal.options.map((optionValue, index) => {
      const isAgainst = proposal.against_position === optionValue || 
                        (proposal.options_text[index] && 
                         (proposal.options_text[index].toLowerCase().includes('against') || 
                          proposal.options_text[index].toLowerCase().includes('no')));
      
      return {
        value: optionValue,
        text: proposal.options_text[index] || `Option ${optionValue}`,
        isAgainst: isAgainst
      };
    });
  }, [proposal]);

  // Calculate threshold based on proposal
  const threshold = useMemo(() => {
    if (!proposal) return 0;
    // Assuming '1740077621' is a specific timestamp relevant to your logic
    // Using current date comparison might be more robust if needed for future proposals:
    // const now = Math.floor(Date.now() / 1000);
    // return now < proposal.end_timestamp && proposal.status === 0 // Check if active
    return proposal.end_timestamp > 1740077621 // Using original logic
      ? proposal.price_direct_buy * 0.1  // 10% for new active proposals
      : 0;  // 0% for old or inactive proposals
  }, [proposal]);

  const thresholdEth = threshold / 1e18;

  // Calculate bids for each option
  const optionBids = useMemo(() => {
    if (!options || !options.length) {
      return [];
    }
    
    // Check if bidsData is available
    if (!bidsData || !bidsData.choiceSums) {
      // Return options with zero bids if no bid data is available
      return options.map(option => ({
        ...option,
        bidSum: 0,
        bidSumEth: 0
      }));
    }

    return options.map(option => {
      const bidSum = bidsData.choiceSums.find(
        sum => sum.choice === option.value
      );
      return {
        ...option,
        bidSum: bidSum ? bidSum.sum : 0,
        bidSumEth: bidSum ? bidSum.sum / 1e18 : 0
      };
    });
  }, [bidsData, options]);

  // Calculate total bids and percentages
  const totalBidsEth = useMemo(() => {
    return optionBids.reduce((total, option) => total + option.bidSumEth, 0);
  }, [optionBids]);

  // Calculate bar heights for visualization
  const barHeights = useMemo(() => {
    if (!optionBids.length) return { threshold: 0 };
    
    const containerHeight = 320; // Conceptual height used for scaling bars
    const maxHeight = 0.75; // Max bar height as fraction of containerHeight
    const minHeight = 0.04; // Min bar height as fraction of containerHeight
    
    // Find max value for scaling (including threshold for relative scaling)
    const allValues = [...optionBids.map(o => o.bidSumEth), thresholdEth];
    const maxValue = Math.max(...allValues, 0.001); // Prevent division by zero, ensure minimum scaling space
    
    // Calculate heights for each option bar in pixels (or relative units)
    const optionHeights = optionBids.reduce((acc, option) => {
      acc[option.value] = Math.max(
        containerHeight * minHeight, // Ensure minimum visible height
        (option.bidSumEth / maxValue) * containerHeight * maxHeight // Scale relative to max value
      );
      return acc;
    }, {});

    // Calculate the threshold line's height relative to the bar area bottom
    // This value is used in the style.bottom calculation later
    optionHeights.threshold = (thresholdEth / maxValue) * containerHeight * maxHeight;
    // Ensure threshold height isn't negative if thresholdEth is 0
    optionHeights.threshold = Math.max(0, optionHeights.threshold);

    return optionHeights;

  }, [optionBids, thresholdEth]);

  // Generate colors for options
  const getOptionColor = (option, index = 0) => {
    // Safety check - if option is undefined, return default colors
    if (!option) {
      return {
        bg: "linear-gradient(135deg, rgb(34 197 94) 0%, rgb(34 197 94 / 0.9) 100%)",
        shadow: "none",
        buttonBg: "bg-green-900/50",
        buttonHoverBg: "hover:bg-green-800",
        buttonShadow: "hover:shadow-[0_0_20px_rgba(34,197,94,0.3)]",
        selectedShadow: "shadow-[0_0_30px_rgba(34,197,94,0.5)]",
        selectedRing: "ring-green-400",
        textColor: "text-green-400",
        baseColor: "rgb(34, 197, 94)"
      };
    }

    // Determine if the bar should have the "striped" background (below threshold)
    const isBelowThreshold = option.bidSumEth < thresholdEth;
    const stripedGray = 'rgb(75 85 99 / 0.6)'; // Equivalent to gray-600 at 60% opacity
    const stripedGrayFaded = 'rgb(75 85 99 / 0.4)'; // Equivalent to gray-600 at 40% opacity
    
    const getStripedBg = (color) => 
      `repeating-linear-gradient(45deg, ${stripedGray}, ${stripedGray} 10px, ${color} 10px, ${color} 20px, ${stripedGrayFaded} 20px, ${stripedGrayFaded} 30px, ${color.replace('0.6', '0.4')} 30px, ${color.replace('0.6', '0.4')} 40px)`;


    // "Against" option - Pink
    if (option.isAgainst) {
      const baseColor = "rgb(236 72 153)"; // pink-500
      const stripeColor = 'rgb(139 0 139 / 0.6)'; // Dark magenta at 60% opacity
      return {
        bg: isBelowThreshold && threshold > 0 
          ? getStripedBg(stripeColor)
          : `linear-gradient(135deg, ${baseColor} 0%, rgba(236, 72, 153, 0.9) 100%)`,
        shadow: !isBelowThreshold || threshold === 0 ? `0 0 30px ${baseColor.replace(')', ', 0.5)')}` : "none",
        buttonBg: option.value === selectedOption ? "bg-pink-500" : "bg-pink-900/50",
        buttonHoverBg: "hover:bg-pink-800",
        buttonShadow: `hover:shadow-[0_0_20px_${baseColor.replace(')', ', 0.3)')}]`,
        selectedShadow: `shadow-[0_0_30px_${baseColor.replace(')', ', 0.5)')}]`,
        selectedRing: "ring-pink-400",
        textColor: "text-pink-400",
        baseColor: baseColor
      };
    }
    
    // "For" option - Green (default for primary option)
    if (option.text?.toLowerCase() === "for" || 
        option.text?.toLowerCase().includes("yes") || 
        index === 0) {
      const baseColor = "rgb(34 197 94)"; // green-500
      const stripeColor = 'rgb(0 100 0 / 0.6)'; // Dark green at 60% opacity
      return {
        bg: isBelowThreshold && threshold > 0
          ? getStripedBg(stripeColor)
          : `linear-gradient(135deg, ${baseColor} 0%, rgba(34, 197, 94, 0.9) 100%)`,
        shadow: !isBelowThreshold || threshold === 0 ? `0 0 30px ${baseColor.replace(')', ', 0.5)')}` : "none",
        buttonBg: option.value === selectedOption ? "bg-green-500" : "bg-green-900/50",
        buttonHoverBg: "hover:bg-green-800",
        buttonShadow: `hover:shadow-[0_0_20px_${baseColor.replace(')', ', 0.3)')}]`,
        selectedShadow: `shadow-[0_0_30px_${baseColor.replace(')', ', 0.5)')}]`,
        selectedRing: "ring-green-400",
        textColor: "text-green-400",
        baseColor: baseColor
      };
    }
    
    // Additional options - Use different colors cyclically
    const additionalColors = [
      { // Blue
        baseColor: "rgb(59 130 246)", // blue-500
        stripeColor: 'rgb(30 64 175 / 0.6)', // dark blue at 60% opacity
        buttonBg: "bg-blue-900/50", buttonHoverBg: "hover:bg-blue-800", selectedBg: "bg-blue-500",
        selectedRing: "ring-blue-400", textColor: "text-blue-400",
      },
      { // Purple
        baseColor: "rgb(168 85 247)", // purple-500
        stripeColor: 'rgb(107 33 168 / 0.6)', // dark purple at 60% opacity
        buttonBg: "bg-purple-900/50", buttonHoverBg: "hover:bg-purple-800", selectedBg: "bg-purple-500",
        selectedRing: "ring-purple-400", textColor: "text-purple-400",
      },
      { // Amber
        baseColor: "rgb(245 158 11)", // amber-500
        stripeColor: 'rgb(180 83 9 / 0.6)', // dark amber at 60% opacity
        buttonBg: "bg-amber-900/50", buttonHoverBg: "hover:bg-amber-800", selectedBg: "bg-amber-500",
        selectedRing: "ring-amber-400", textColor: "text-amber-400",
      },
      { // Cyan
        baseColor: "rgb(8 145 178)", // cyan-600
        stripeColor: 'rgb(14 116 144 / 0.6)', // dark cyan at 60% opacity
        buttonBg: "bg-cyan-900/50", buttonHoverBg: "hover:bg-cyan-800", selectedBg: "bg-cyan-600",
        selectedRing: "ring-cyan-400", textColor: "text-cyan-400",
      }
      // Add more colors here if needed
    ];
    
    // Assign colors based on index (excluding the first 'for'/'yes'/index=0 and 'against')
    const colorIndex = (index - 1 + additionalColors.length) % additionalColors.length; // Cycle through colors
    const selectedColor = additionalColors[colorIndex];

    return {
      bg: isBelowThreshold && threshold > 0
        ? getStripedBg(selectedColor.stripeColor)
        : `linear-gradient(135deg, ${selectedColor.baseColor} 0%, ${selectedColor.baseColor.replace(')', ', 0.9)')} 100%)`,
      shadow: !isBelowThreshold || threshold === 0 ? `0 0 30px ${selectedColor.baseColor.replace(')', ', 0.5)')}` : "none",
      buttonBg: option.value === selectedOption ? selectedColor.selectedBg : selectedColor.buttonBg,
      buttonHoverBg: selectedColor.buttonHoverBg,
      buttonShadow: `hover:shadow-[0_0_20px_${selectedColor.baseColor.replace(')', ', 0.3)')}]`,
      selectedShadow: `shadow-[0_0_30px_${selectedColor.baseColor.replace(')', ', 0.5)')}]`,
      selectedRing: selectedColor.selectedRing,
      textColor: selectedColor.textColor,
      baseColor: selectedColor.baseColor
    };
  };


  // Log to console when an option is selected (without placing a bid)
  useEffect(() => {
    if (selectedOption !== null) {
      const selectedOptionObj = optionBids.find(opt => opt.value === selectedOption);
    //   console.log('Selected bid choice:', {
    //     option: selectedOption,
    //     optionText: selectedOptionObj?.text || 'Unknown option',
    //     isAgainst: selectedOptionObj?.isAgainst || false
    //   });
    }
  }, [selectedOption, optionBids]);
  
  const handleInputChange = (e) => {
    const value = e.target.value; // Keep as string initially for better input control
    // Allow empty input or valid numbers (including decimals)
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
        setUserInput(value); // Update state with the string value
        // Validate only if the value is not empty
        if (value !== "") {
            const numValue = parseFloat(value);
            if (isNaN(numValue)) {
                 setInputError("Invalid number");
            } else if (numValue < minBid) {
                setInputError(`Minimum bid is ${minBid} ETH`);
            } else {
                setInputError(""); // Clear error if valid and meets minimum
            }
        } else {
             setInputError(""); // Clear error if empty
        }
    }
  };

  // Function to connect the wallet
  const handleConnectWallet = async () => {
    if (!isConnected && connectors.length > 0) { // Check if connectors are available
      try {
        // Attempt to connect using the first available connector
        await connect({ connector: connectors[0] });
      } catch (error) {
        console.error("Failed to connect wallet:", error);
        // Optionally set an error message for the user
      }
    }
  };

  // Determine if the bid button should be disabled
  const isBidButtonDisabled = useMemo(() => {
      if (!isConnected || currentChainId !== proposalNetwork) {
          return false; // Button actions are connect/switch network, so not disabled in this context
      }
      // If connected and on correct network, disable based on bid criteria
      const numericUserInput = parseFloat(userInput); // Convert user input string to number for comparison
      return (
          selectedOption === null ||
          userInput === "" || // Check if input is empty
          isNaN(numericUserInput) || // Check if input is not a valid number
          proposal.status !== 0 || // Check if proposal is active (status 0)
          numericUserInput < minBid // Check against minimum bid
      );
  }, [isConnected, currentChainId, proposalNetwork, selectedOption, userInput, proposal?.status, minBid]);

  // Determine the text for the main action button
  const getButtonText = () => {
    if (!isConnected) {
      return isConnecting ? "Connecting..." : "Connect Wallet";
    }
    if (currentChainId !== proposalNetwork) {
      return "Switch Network";
    }
    return "Place your bid";
  };


  return (
    <div className="bg-[#343434] rounded-lg shadow-lg p-3 sm:p-5 mt-4 sm:mt-6 flex flex-col justify-center items-center border border-[#96fdbf] max-w-xl mx-auto">
      {/* Title and Link */}
      <h2 className="text-lg sm:text-xl text-[#96fdbf] mb-2">
        Syndicate auction
      </h2>
      <a
        href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/how-does-votes-acquisition-work"
        target="_blank"
        rel="noopener noreferrer"
        className="text-xs text-[#96fdbf] hover:text-[#3DFF54] mb-4 sm:mb-6"
      >
        how does it work?
      </a>

      {/* Loading State */}
      {loadingBids ? (
        <div className="h-48 sm:h-64 w-full flex items-center justify-center">
          <p className="text-[#96fdbf]">Loading...</p>
        </div>
      ) : (
        // Visualization Area (Outer container)
        <div className="relative w-full">
          
          {/* Sticky Threshold Label */}
          {threshold > 0 && (
            <div className="sticky top-4 w-full flex justify-center z-20 pointer-events-none">
              <div className="bg-black/80 px-2 sm:px-3 py-1 rounded shadow-lg">
                <span className="text-yellow-400 text-xs sm:text-sm whitespace-nowrap">
                  Threshold: {thresholdEth.toFixed(4)} ETH
                </span>
              </div>
            </div>
          )}
          
          {/* == Threshold Line - MOVED HERE == */}
          {/* Positioned absolutely relative to the outer 'relative w-full' div */}

          {threshold > 0 && !loadingBids && (
          <div
            // The dashed line itself
            className="absolute border-t-2 border-dashed border-yellow-400 z-10 pointer-events-none"
            style={{
              // Vertical position: same as label below, determines where the line sits
              bottom: `calc(1rem + ${barHeights.threshold}px)`,
              opacity: 0.7,
              width: '100%', // Spans the full width of the non-scrolling parent
              left: 0,
              right: 0,
            }}
            aria-hidden="true" // Hide decorative line from screen readers
          />
        )}

          {threshold > 0 && !loadingBids && (
            <div
            // Container for the label, positioned absolutely
            className="absolute right-2 sm:right-4 z-10 pointer-events-none transform translate-y-1/2" 
            // Positioning:
            // - `absolute`: Relative to the outer non-scrolling div
            // - `right-2 sm:right-4`: Places it near the right edge with some padding
            // - `z-10`: Same layer as the line, below the sticky top label
            // - `pointer-events-none`: Prevents blocking interactions
            // - `transform translate-y-1/2`: Shifts the label *down* by half its height, vertically centering it on the line defined by 'bottom'
            style={{
              // Vertical position: Must be identical to the line's bottom style
              bottom: `calc(1rem + ${barHeights.threshold}px)`, 
            }}
          >
            {/* The visible label box */}
            <div className="px-2 py-0.5 mb-4 rounded"> {/* Adjusted padding for compactness */}
              <span className="text-yellow-400 text-xs whitespace-nowrap font-medium">
                {/* Display the formatted threshold value */}
                {thresholdEth.toFixed(4)} ETH
              </span>
            </div>
          </div>
          )}

          {/* Scrollable Container for Bars */}
          <div className={`relative w-full ${optionBids.length > 4 ? 'overflow-x-auto pb-4' : ''}`}>
            
            {/* Container that defines height and holds bars */}
            <div
              // Added 'relative' here for positioning context of absolutely positioned elements within/above it if needed.
              className={`relative h-[330px] flex ${optionBids.length > 4 ? '' : 'w-full'} ${optionBids.length > 4 ? 'justify-start gap-4 sm:gap-12' : 'justify-around'} items-end mb-8 sm:mb-12 px-2 sm:px-4`}
            >
              {/* Option Bars */}
              {optionBids.map((option, index) => {
                try {
                  const colorScheme = getOptionColor(option, index);
                  const barHeightPx = barHeights[option.value] || 0;
                  const showThresholdMetText = option.bidSumEth >= thresholdEth && threshold > 0;

                  return (
                    // Individual Bar Wrapper - Make relative for label positioning
                    <div 
                      key={`bar-${option.value}`} 
                      className="relative w-16 sm:w-24 flex flex-col items-center flex-shrink-0"
                    >
                      {/* Text Labels above the bar */}
                      <div className="absolute -top-20 sm:-top-24 left-0 right-0 text-center space-y-1 sm:space-y-2">
                        <div className="relative group">
                          <p className="text-[#96fdbf] text-sm sm:text-base font-semibold px-1 cursor-help">
                            {option.text && option.text.length > (optionBids.length > 3 ? 6 : 10) 
                              ? `${option.text.substring(0, optionBids.length > 3 ? 6 : 10)}...` 
                              : option.text || `Option ${index + 1}`}
                          </p>
                          {/* Tooltip with full text */}
                          {option.text && option.text.length > (optionBids.length > 3 ? 6 : 10) && (
                            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 bg-black/90 text-[#96fdbf] text-sm rounded-lg shadow-lg whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-50 border border-[#96fdbf]/30 shadow-[0_0_15px_rgba(150,253,191,0.2)]">
                              {option.text}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col items-center gap-1">
                          <span className={`text-xs sm:text-sm font-medium ${colorScheme.textColor} bg-black/80 px-2 sm:px-3 py-1 rounded-lg shadow-lg whitespace-nowrap`}>
                            {option.bidSumEth.toFixed(4)} ETH
                          </span>
                        </div>
                      </div>
                      
                      {/* The Bar itself */}
                      <motion.div
                        className="w-full rounded-t" // Removed 'relative' here as wrapper is relative now
                        initial={{ height: 0 }}
                        animate={{ height: barHeightPx }}
                        transition={{ duration: 1, type: "spring", bounce: 0.3 }}
                        style={{
                          background: colorScheme.bg,
                          boxShadow: colorScheme.shadow,
                        }}
                      >
                        {/* "Threshold met" text inside the bar */}
                        {showThresholdMetText && (
                          <div className="absolute inset-0 flex items-center justify-center p-1 pointer-events-none">
                             {/* Example styling - adjust as needed */}
                             {/* Consider showing only if bar is tall enough */}
                             {barHeightPx > 40 && ( 
                                <span className="text-black text-[10px] text-center sm:text-xs font-bold px-1 rounded"> 
                                  Threshold Met
                                </span>
                             )}
                          </div>
                        )}
                      </motion.div>
                    </div> // End Individual Bar Wrapper
                  );
                } catch (error) {
                  console.error("Error rendering option bar:", error, option);
                  return null; // Skip rendering this bar if there's an error
                }
              })} {/* End map Option Bars */}
            </div> {/* End h-[330px] flex container */}
          </div> {/* End Scrollable Container */}
        </div> // End Outer 'relative w-full' container
      )}

      {/* --- Controls Area --- */}
      <div className="w-full flex flex-col gap-3 sm:gap-4 mt-4"> {/* Added mt-4 for spacing */}
        {/* Option selection buttons */}
        <div className={`grid ${optionBids.length > 3 ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-1 sm:grid-cols-' + Math.max(1, optionBids.length)} gap-2 sm:gap-4`}>
          {optionBids.map((option, index) => {
            try {
              const colorScheme = getOptionColor(option, index);
              const optionText = option.text || `Option ${index + 1}`;
              
              return (
                <button
                  key={`btn-${option.value}`}
                  onClick={() => setSelectedOption(option.value)}
                  className={`px-3 py-2 rounded-lg transition-all border-2 transform duration-300 text-sm font-medium ${ // Added font-medium
                    option.value === selectedOption
                      ? `${colorScheme.selectedBg || colorScheme.buttonBg.replace('900/50', '500')} text-white border-white ${colorScheme.selectedShadow} scale-105 ring-2 ring-offset-2 ring-offset-[#343434] ${colorScheme.selectedRing} animate-pulse` // Enhanced selected style
                      : `${colorScheme.buttonBg} text-[#96fdbf] ${colorScheme.buttonHoverBg} border-[#96fdbf] ${colorScheme.buttonShadow} hover:border-white` // Enhanced non-selected style
                  }`}
                  title={optionText} // Show full text on hover
                >
                  {/* Truncate option text with ellipsis if too long */}
                  <span className="block truncate">
                    {optionText.length > 15 
                      ? `${optionText.substring(0, 15)}...` 
                      : optionText}
                  </span>
                </button>
              );
            } catch (error) {
              console.error("Error rendering option button:", error, option);
              return null; // Skip rendering this button if there's an error
            }
          })}
        </div>

        {/* Input field for bid amount */}
        <div className="flex justify-center w-full">
            <input
              type="text" // Use text for better control over input format
              inputMode="decimal" // Hint for mobile keyboards
              min={minBid}
              step="0.0001" // Step doesn't work well with type="text", but good for context
              placeholder={`Enter bid (ETH)`}
              className={`border ${inputError ? 'border-red-500' : 'border-[#96fdbf]'} bg-black/50 text-[#96fdbf] rounded-lg p-2 text-center w-full max-w text-sm sm:text-base focus:ring-2 focus:ring-[#96fdbf] focus:border-transparent outline-none`} // Added outline-none
              onChange={handleInputChange}
              value={userInput}
              // Disable input if wallet not connected or on wrong network? Optional.
              // disabled={!isConnected || currentChainId !== proposalNetwork} 
            />
        </div>

        {/* USD Conversion Display */}
        {/* Check if userInput is a valid number before calculating */}
        {userInput && !isNaN(parseFloat(userInput)) && conversionRate && (
          <p className="text-xs text-center text-[#96fdbf] bg-black/30 py-1 px-2 rounded">
            ≈ {(parseFloat(userInput) * conversionRate).toFixed(2)} USD
          </p>
        )}
      </div>

      {/* Button and messages container */}
      <div className="w-full flex flex-col items-center gap-2 mt-4"> {/* Added mt-4 */}
        {/* Action Button (Connect / Switch / Place Bid) */}
        <button
          className={`w-full p-3 sm:p-4 rounded-lg transition-all transform text-sm sm:text-base font-semibold ${ // Added font-semibold
              isBidButtonDisabled && isConnected && currentChainId === proposalNetwork // Specific condition for disabled state *when* bid is the action
              ? "bg-[#cccccc] text-[#666666] cursor-not-allowed" // Disabled style
              : "bg-[#96fdbf] text-[#1A1A1A] hover:bg-[#7fdba7] shadow-lg hover:shadow-[#96fdbf]/20 hover:scale-105" // Active style
          }`}
          onClick={async () => {
            try {
              // Step 1: Connect wallet if not connected
              if (!isConnected) {
                await handleConnectWallet();
                return;
              }

              // Step 2: Switch network if on wrong network
              if (currentChainId !== proposalNetwork) {
                // Add loading/switching state indication if desired
                await switchChainAsync({ chainId: proposalNetwork });
                return;
              }

              // Step 3: Place bid if everything else is ready (button not disabled)
              if (!isBidButtonDisabled) {
                 const numericUserInput = parseFloat(userInput); // Ensure numeric value is passed
                 if (selectedOption !== null && !isNaN(numericUserInput) && proposal.status === 0) {
                    await onBid(selectedOption, numericUserInput); // Pass numeric value
                 }
              }
            } catch (error) {
              console.error("Action failed:", error);
              // Handle specific errors like user rejecting switch network etc.
            }
          }}
          disabled={isBidButtonDisabled && isConnected && currentChainId === proposalNetwork} // Only truly disable when the action is 'Place Bid' and criteria aren't met
        >
          {getButtonText()}
        </button>
        
        {/* Input Validation Error */}
        {inputError && <p className="text-red-500 text-xs mt-1">{inputError}</p>}

        {/* Transaction Status Messages */}
        <div className="w-full mt-4 space-y-2 text-center">
          {/* Pending Transaction */}
          {txStatus === "pending" && (
             <p className="text-[#96fdbf] animate-pulse">Transaction in progress...</p> // Added pulse animation
          )}

          {/* Success Message */}
          {txStatus === "success" && txHash && ( // Ensure txHash exists
            <div className="space-y-1 p-2 bg-green-900/30 rounded-md border border-green-500">
              <p className="text-green-400 font-medium">
                Transaction successful!
              </p>
              {/* Provide link to explorer if possible */}
              <p className="text-[#96fdbf] text-xs break-all">
                 Tx Hash: {txHash}
              </p>
            </div>
          )}

          {/* Error Message */}
          {txStatus === "error" && (
             <div className="space-y-1 p-2 bg-red-900/30 rounded-md border border-red-500">
                <p className="text-red-400 font-medium">Transaction Failed</p>
                {errorMessage && (
                   <p className="text-red-400 text-xs break-words"> 
                      {/* Attempt to provide a cleaner error message */}
                      {errorMessage.length > 100 ? errorMessage.substring(0, 100) + '...' : errorMessage}
                   </p>
                )}
            </div>
          )}

        </div>
      </div> {/* End Button and messages container */}
    </div> // End Main Component Wrapper
  );
};

export default AuctionVisualization;
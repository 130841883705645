import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import AuctionVisualization from "./components/AuctionModel";
import ReactMarkdown from "react-markdown";
import { config } from "./config";
import { contractsByChainID, contractsByName } from "./contracts";
import {
  useBalance,
  useWriteContract,
  useSwitchChain,
  useAccount,
  useWaitForTransactionReceipt,
  useSendTransaction,
  WagmiProvider,
  useConnect,
} from "wagmi";
import { getChainId, getAccount } from "@wagmi/core";
import VectorGrid from "./assets/vector_grid.svg";
import { ContractFunctionExecutionError } from "viem";
import { parseEther } from "viem";
import { ethers } from "ethers";
import { motion } from "framer-motion";

const GET_PROPOSAL_BY_ID_QUERY = gql`
  query GetProposalById($id: ID!) {
    proposal(id: $id) {
      id
      type
      title
      status
      network
      address
      author
      trx_hash
      start_block
      end_block
      start_timestamp
      end_timestamp
      is_auction
      received_eth
      price_direct_buy
      voted_option
      options
      options_text
      text
      available_votes
      against_position
    }
  }
`;

const GET_BIDS_BY_ID_QUERY = gql`
  query GetChoiceSums($proposal_id: String!) {
    choiceSums(proposal_id: $proposal_id) {
      proposal_id
      choice
      sum
    }
  }
`;

const GET_BIDS_BY_ID_USER_QUERY = gql`
  query GetBids($proposal_id: String!, $bidder: String!) {
    bids(proposal_id: $proposal_id, bidder: $bidder) {
      id
      proposal_id
      bidder
      choice
      amount
      timestamp
    }
  }
`;

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const ProposalDetail = () => {
  let { id } = useParams(); // This hooks allows you to access the :id param from the URL
  const { chainss, switchChain, switchChainAsync } = useSwitchChain();

  const [selectedChoice, setSelectedChoice] = useState("");
  const [conversionRate, setConversionRate] = useState(null);
  const { connectors, connect } = useConnect();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [inputError, setInputError] = useState("");

  const [txStatus, setTxStatus] = useState(null);
  const [txHash, setTxHash] = useState(null);

  const [startedInstantBuy, setStartedInstantBuy] = useState(false);
  const [errorsInstantBuy, setErrorsInstantBuy] = useState();
  const [errorsAddBid, seterrorsAddBid] = useState();

  const [txStatusInstantBuy, setTxStatusInstantBuy] = useState(null);
  const [txHashInstantBuy, setTxHashInstantBuy] = useState(null);

  const { isConnected } = useAccount();
  const currentChainId = getChainId(config);
  const account = getAccount(config);

  const erc20ToDaoMapping = {
    "0xcea06446e5d302c258e9a59ecc10e09f34641a47": "TEST DAO",
    "0xf07ded9dc292157749b6fd268e37df6ea38395b9": "ARBITRUM DAO",
    "0x789fc99093b09ad01c34dc7251d0c89ce743e5a4": "ARBITRUM DAO",
    "0xcdf27f107725988f2261ce2256bdfcde8b382b10": "OPTIMISM DAO",
    "0x76705327e682f2d96943280d99464ab61219e34f": "ZKSYNC DAO",
    "0x10560f8b7ee37571ad7e3702eeb12bc422036e89": "ZKSYNC DAO",
    "0x496869a7575a1f907d1c5b1eca28e4e9e382afab": "ZKSYNC DAO",
    "0x2f3f2054776bd3c2fc30d750734a8f539bb214f0": "SCROLL DAO",
  };

  const minBidByDao = {
    "ZKSYNC DAO": 0.00001, // Higher minimum for zkSync
    "ARBITRUM DAO": 0.001,
    "OPTIMISM DAO": 0.00001,
    "TEST DAO": 0.00001,
    "SCROLL DAO": 0.00001,
  };

  // Adjust the useQuery hook to use the filter state as a variable
  const {
    loading,
    error,
    data: dataProposal,
  } = useQuery(GET_PROPOSAL_BY_ID_QUERY, {
    variables: { id },
    client,
  });
  const [showFullBody, setShowFullBody] = useState(false);

  const proposal = dataProposal?.proposal || {};

  const currentDao = erc20ToDaoMapping[proposal.address?.toLowerCase()];
  const minBid = minBidByDao[currentDao] || 0.001;

  // here we should calculate the time left

  const fetchConversionRate = async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const data = await response.json();
      // Adjust the ID if needed
      const rate = data.ethereum ? data.ethereum.usd : null;
      setConversionRate(rate);
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
    }
  };

  const refetchBidsData = async () => {
    try {
      if (!account.address) return; // Don't refetch if no wallet is connected

      await new Promise((resolve) => setTimeout(resolve, 2000));
      await refetchBids();
      if (account.address) {
        // Only refetch user bids if wallet is connected
        await refetchUserBids();
      }
    } catch (error) {
      console.error("Error refetching bids data:", error);
    }
  };

  const {
    loadingUserBids,
    errorUserBids,
    data: dataUserBids,
    refetch: refetchUserBids,
  } = useQuery(GET_BIDS_BY_ID_USER_QUERY, {
    variables: {
      proposal_id: id,
      bidder: account?.address?.toLowerCase() || "", // Provide a default empty string
    },
    skip: !account.address, // Skip the query if no wallet is connected
    client,
  });

  const { lobbyFi } = useMemo(() => {
    if (proposal && proposal.network && contractsByChainID[proposal.network]) {
      return contractsByChainID[proposal.network];
    }
    return {};
  }, [proposal]);

  const { data: hash, isPending, writeContract } = useWriteContract();

  const { data: hash1, isPending1, sendTransaction } = useSendTransaction();

  const addNewBid = async (BidChoice, amount) => {
    if (isConnected) {
      if (account.chainId !== proposal.network) {
        try {
          await switchChainAsync({ chainId: proposal.network });
        } catch (error) {
          console.error("Failed to switch network:", error);
          return;
        }
      }
      try {
        setTxStatus("pending"); // Set the transaction status to pending
        const ProxyAddress = lobbyFi.ProxyAddress?.toLowerCase();
        const ProxyABI = lobbyFi.ProxyAbi;
        const provider = new ethers.providers.Web3Provider(window.ethereum); // replace with your provider
        const signer = provider.getSigner();
        const proxy = new ethers.Contract(ProxyAddress, ProxyABI, signer);
        const tx = await proxy.placeBid(id, BidChoice, {
          value: ethers.utils.parseEther(amount.toString()), // Use the passed amount parameter
        });
        setTxStatus("success"); // Set the transaction status to success
        setTxHash(tx.hash); // Set the transaction hash
        await refetchBidsData();
      } catch (error) {
        setTxStatus("error"); // Set the transaction status to error
        console.error(error);

          // Handle bid support mismatch error
        if (error.reason?.includes("Bid support mismatch") || 
        error.error?.data?.originalError?.message?.includes("Bid support mismatch")) {
        seterrorsAddBid("You can not bid for both options.");
        }
        // Handle user rejection
        else if (error.code === 4001) {
        seterrorsAddBid("Transaction rejected by user.");
        }
        // Handle RPC errors (including insufficient funds)
        else if (error.code === -32603) {
        if (error.data && error.data.message) {
          seterrorsAddBid(error.data.message);
        }
        }
        // Handle all other errors
        else {
        seterrorsAddBid(error.code || "Transaction failed. Please try again.");
        }
        
        }
      } else {
        alert("Connect wallet!");
      }
  };

  useEffect(() => {
    fetchConversionRate();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Math.floor(Date.now() / 1000); // Current time in seconds
      const end = proposal.end_timestamp - 36000;
      const diff = end - now;

      if (diff > 0) {
        const days = Math.floor(diff / (60 * 60 * 24));
        const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((diff % (60 * 60)) / 60);
        const seconds = diff % 60;

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [proposal.end_timestamp]);

  const {
    loadingBids,
    errorBids,
    data: bidsData,
    refetch: refetchBids,
  } = useQuery(GET_BIDS_BY_ID_QUERY, {
    variables: { proposal_id: id },
    client,
  });

  // Combine loading and error states for both queries
  if (loading)
    return (
      <div
        className="text-[#96fdbf] bg-black min-h-screen flex items-center justify-center"
        style={{
          fontFamily: "var(--body-text-font-family)",
          height: "100vh",
          width: "100%",
        }}
      >
        Loading...
      </div>
    );
  // Early return for error or no proposal found
  if (error || !dataProposal || !dataProposal.proposal) {
    return (
      <div className="bg-black text-[#96fdbf] min-h-screen pt-8">
        <div className="flex flex-col items-center justify-between w-full">
          <Navbar />
        </div>
        <div className="max-w-6xl mx-auto px-4 text-center mt-20">
          <div className="bg-[#343434] rounded-lg shadow-lg p-8">
            <h1
              className="text-2xl mb-4"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              Proposal Not Found
            </h1>
            <p
              className="mb-6"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              The proposal you're looking for doesn't exist. Neo wake up!
            </p>
            <Link
              to="/Proposals"
              className="rounded inline-block bg-[#96fdbf] text-black px-6 py-3 hover:bg-[#3DFF54]"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              Back to Proposals
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const compressedBody =
    proposal.text.length > 100
      ? proposal.text.substring(0, 100) + "..."
      : proposal.text;

  const plusMappingIndex = proposal.type === 0 ? 1 : 0; // we use it to add it to the index of the options in the instant buy for snapshot

  const handleChoiceChange = (event) => {
    const index = parseInt(event.target.value, 10);

    const selectedChoice = proposal.options[index];
    setSelectedChoice(selectedChoice);
    // console.log('Selected option:', selectedChoice);
  };

  const handleClick = () => {
    alert("Coming soon"); // Display coming soon message
  };

  const handleInstantBuy = async () => {
    try {
      setErrorsInstantBuy("");
      setStartedInstantBuy(true);
      if (!isConnected) {
        await connectAsync({ chainId: sepolia.id, connector: injected() });
      }

      if (account.chainId !== proposal.network) {
        try {
          await switchChainAsync({ chainId: proposal.network });
        } catch (error) {
          console.error("Failed to switch network:", error);
          return;
        }
      }

      setTxStatusInstantBuy("pending"); // Set the transaction status to pending
      const ProxyAddress = lobbyFi.ProxyAddress?.toLowerCase();
      const ProxyABI = lobbyFi.ProxyAbi;
      const provider = new ethers.providers.Web3Provider(window.ethereum); // replace with your provider
      const signer = provider.getSigner();
      const proxy = new ethers.Contract(ProxyAddress, ProxyABI, signer);

      let choiceValue = selectedChoice;
      if (proposal.type === 1) {
        // on-chain
        choiceValue = selectedChoice === 1 ? 1 : 0; // 1 for For, 0 for Against
      }
      // console.log("choiceValue: ", choiceValue);
      const tx = await proxy.instantBuy(id, choiceValue, {
        value: ethers.BigNumber.from(proposal.price_direct_buy.toString()), // Add this if you need to send ETH
      });

      setTxStatusInstantBuy("success"); // Set the transaction status to success
      setTxHashInstantBuy(tx.hash); // Set the transaction hash
      console.log(tx);
    } catch (err) {
      setTxStatusInstantBuy("error"); // Set the transaction status to error
      if (err.code === -32603) {
        if (err.data && err.data.message) {
          setErrorsInstantBuy(err.data.message);
        }
      } else {
        setErrorsInstantBuy(
          err.code || "Transaction failed. Please try again."
        );
      }
      console.log("error:", err);
      setStartedInstantBuy(false);
    }
  };

  // For now, just display the ID to verify it's being passed correctly
  return (
    <ApolloProvider client={client}>
      <div
        className="bg-black text-[#96fdbf] min-h-screen"
        style={{ fontFamily: "var(--body-text-font-family)" }}
      >
        <div className="flex flex-col  items-center justify-between w-full">
          <Navbar />
          <InfoBar />
        </div>
        <div className="max-w-6xl mx-auto px-4">
          <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
            <Link
              to="/Proposals"
              className="rounded inline-block mt-2 bg-[#96fdbf] text-black px-4 py-2 hover:bg-[#3DFF54] text-sm sm:text-base"
            >
              Back to proposals
            </Link>
            <h2 className="text-lg sm:text-xl font-bold mt-4 mb-2">
              {proposal.title}
            </h2>
            <div className="flex space-x-2 mb-8">
              <span
                className={`inline-block px-2 py-1 rounded ${
                  proposal.status === 0
                    ? "bg-blue-200 text-blue-800"
                    : "bg-red-200 text-red-800"
                }`}
              >
                {proposal.status === 0 ? "active" : "closed"}
              </span>
              <span className="inline-block bg-yellow-200 text-yellow-800 px-2 py-1 rounded">
                {proposal.type === 0 ? "off-chain" : "on-chain"}
              </span>
              <span className="inline-block bg-orange-200 text-orange-800 px-2 py-1 rounded">
                {erc20ToDaoMapping[proposal.address?.toLowerCase()] ||
                  "Unknown DAO"}
              </span>
            </div>
            {/* Countdown Timer */}
            <div className="flex justify-center mb-1">
              <div
                className="inline-flex bg-black text-[#96fdbf] px-2 py-1 rounded border border-[#96fdbf] font-mono mb-2"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <div className="flex flex-col items-center mx-1">
                  <span className="text-xl">
                    {String(timeLeft.days).padStart(2, "0")}
                  </span>
                  <span className="text-xs">DAYS</span>
                </div>
                <div className="flex flex-col items-center mx-1">
                  <span className="text-xl">
                    {String(timeLeft.hours).padStart(2, "0")}
                  </span>
                  <span className="text-xs">HRS</span>
                </div>
                <div className="flex flex-col items-center mx-1">
                  <span className="text-xl">
                    {String(timeLeft.minutes).padStart(2, "0")}
                  </span>
                  <span className="text-xs">MIN</span>
                </div>
                <div className="flex flex-col items-center mx-1">
                  <span className="text-xl">
                    {String(timeLeft.seconds).padStart(2, "0")}
                  </span>
                  <span className="text-xs">SEC</span>
                </div>
              </div>
            </div>
            <div className="flex justify-center mb-1">
              <div className="text-center">
                <span
                  className="inline-block bg-black text-[#96fdbf] px-2 py-1 rounded border border-[#96fdbf] font-mono mb-2"
                  style={{ fontFamily: "var(--body-text-font-family)" }}
                >
                  Available votes:{" "}
                  {Number(
                    proposal.available_votes / Math.pow(10, 18)
                  ).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>

            <div className="flex justify-center mb-1">
              <div className="text-center">
                {proposal.status === 1 && (
                  <p className="text-red-500 mt-2 px-4 py-1 text-xl">
                    Proposal is inactive, you cannot acquire votes anymore.
                  </p>
                )}
              </div>
            </div>
            {proposal.is_auction && (
              <>
                <AuctionVisualization
                  proposal={proposal}
                  bidsData={bidsData}
                  loadingBids={loadingBids}
                  onBid={async (optionValue, amount) => {
                    try {
                      console.log('Placing bid with option:', optionValue, 'and amount:', amount);
                      await addNewBid(optionValue, amount);
                    } catch (error) {
                      console.error("Error in handleBidClick:", error);
                    }
                  }}
                  conversionRate={conversionRate}
                  minBid={minBid}
                  isConnected={isConnected}
                  currentChainId={account.chainId}
                  proposalNetwork={proposal.network}
                  txStatus={txStatus}
                  txHash={txHash}
                  errorMessage={errorsAddBid}
                />
                
                {/* User Bids Section */}
                {dataUserBids && dataUserBids.bids.length > 0 && (
                  <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-6 border border-[#96fdbf] max-w-xl mx-auto">
                    <div className="w-full text-xs sm:text-sm md:text-base">
                      {loadingUserBids && (
                        <span className="block text-center">Loading bids...</span>
                      )}
                      {errorUserBids && (
                        <span className="block text-center text-red-500">
                          Error: {errorUserBids.message}
                        </span>
                      )}
                      <div className="max-w-2xl mx-auto px-4">
                        <h3 className="text-center font-bold mb-2">Your Bids</h3>
                        <div className="space-y-2">
                          {dataUserBids.bids.map((bid, index) => {
                            // Find matching option text for this bid
                            const optionText = proposal.options.findIndex(opt => opt === bid.choice) !== -1 
                              ? proposal.options_text[proposal.options.findIndex(opt => opt === bid.choice)]
                              : bid.choice === 1 ? "For" : "Against";
                            
                            // Determine if this is an "against" option
                            const isAgainst = bid.choice === proposal.against_position;
                            
                            return (
                              <div
                                key={index}
                                className={`flex flex-col sm:flex-row sm:justify-between items-start sm:items-center text-${
                                  isAgainst ? "pink-500" : "green-500"
                                } bg-black p-2 rounded-lg`}
                              >
                                <span className="mb-1 sm:mb-0 sm:mr-2">
                                  {new Date(bid.timestamp * 1).toLocaleString()}
                                </span>
                                <span className="mb-1 sm:mb-0 sm:mr-2 font-semibold">
                                  {optionText}
                                </span>
                                <span className="font-bold">
                                  {(bid.amount / 1e18).toFixed(5)} ETH
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div
              className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12 flex flex-col justify-center items-center border max-w-xl mx-auto"
              style={{ borderColor: "#96fdbf" }}
            >
              <div>
                <div className="text-center mb-6">
                  <h2 className="text-[#96fdbf] text-xl mb-2">Instant Buy</h2>
                  <a
                    href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/how-does-votes-acquisition-work"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#96fdbf] text-xs hover:text-[#3DFF54]"
                  >
                    how does it work?
                  </a>
                </div>
                <div className="w-full mt-4 space-y-4">
                  {proposal.options_text.map((choice, index) => (
                    <button
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChoiceChange({ target: { value: index } });
                      }}
                      className={`w-full px-4 py-2 rounded transition-all border-2 transform duration-300 ${
                        selectedChoice === proposal.options?.[index]
                          ? "bg-green-500 text-black border-white shadow-[0_0_30px_rgba(34,197,94,0.5)] scale-105 ring-2 ring-offset-2 ring-green-400 animate-pulse"
                          : "bg-green-900 text-[#96fdbf] hover:bg-green-800 border-[#96fdbf]"
                      }`}
                    >
                      {choice}
                    </button>
                  ))}
                </div>
                <h2 className="text-xl font-bold mt-4 mb-2 text-center">
                  Price: {proposal.price_direct_buy / Math.pow(10, 18)} ETH
                </h2>
                <p className="text-xs mt-4 mb-2 text-center">
                  ≈{" "}
                  {(
                    (proposal.price_direct_buy / Math.pow(10, 18)) *
                    conversionRate
                  ).toFixed(2)}{" "}
                  USD
                </p>
              </div>
              <div className="w-full">
                <button
                  className={`w-full mt-4 p-4 rounded-lg transition-all transform hover:scale-105 ${
                    isConnected &&
                    selectedChoice !== null &&
                    selectedChoice !== undefined &&
                    selectedChoice !== "" &&
                    proposal.status === 0
                      ? "bg-[#96fdbf] text-[#1A1A1A]"
                      : "bg-[#cccccc] text-[#1A1A1A]" +
                        (!isConnected ? "" : " cursor-not-allowed")
                  }`}
                  onClick={async () => {
                    if (!isConnected) {
                      // Connect wallet if not connected
                      const connector = connectors[0];
                      if (connector) {
                        try {
                          await connect({ connector });
                          return;
                        } catch (error) {
                          console.error(
                            "Connection request reset. Please try again:",
                            error
                          );
                          return;
                        }
                      }
                      return;
                    }

                    // Return early if conditions aren't met
                    if (
                      selectedChoice === null ||
                      selectedChoice === undefined ||
                      selectedChoice === "" ||
                      proposal.status !== 0 ||
                      !proposal ||
                      !proposal.network
                    ) {
                      return;
                    }

                    // Call original handleInstantBuy function
                    handleInstantBuy();
                  }}
                >
                  {!isConnected
                    ? "Connect Wallet"
                    : !proposal || !proposal.network
                    ? "Loading..."
                    : account.chainId !== proposal.network
                    ? "Change Network"
                    : "Instant Buy"}
                  {txStatusInstantBuy === "pending" &&
                    "Transaction is pending..."}
                </button>
              </div>
              <div className="w-full mt-4 text-xs sm:text-sm md:text-base">
                {txStatusInstantBuy === "success" && (
                  <>
                    <span className="text-green-500">
                      Transaction was successful.
                    </span>
                    <br />
                    <span className="text-[#96fdbf] break-all">
                      Hash: {txHashInstantBuy}
                    </span>
                  </>
                )}
                {txStatusInstantBuy === "error" && (
                  <span className="text-red-500">
                    {errorsInstantBuy || "Transaction failed"}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12 overflow-auto break-words">
            <h2 className="text-lg sm:text-xl font-bold mt-4 mb-2">
              Proposal details:
            </h2>
            <div className="text-xs sm:text-sm mb-4">
              <p>
                <strong>ID:</strong> {proposal.id}
              </p>
              <p>
                <strong>Author:</strong> {proposal.author}
              </p>
              <p>
                <strong>Start Block:</strong> {proposal.start_block}
              </p>
              <p>
                <strong>Start Timestamp:</strong> {proposal.start_timestamp}
              </p>
              <p>
                <strong>End Block:</strong> {proposal.end_block}
              </p>
              <p>
                <strong>End Timestamp:</strong> {proposal.end_timestamp}
              </p>
              <p>
                <strong>Transaction Hash:</strong> {proposal.trx_hash}
              </p>
            </div>
            <div className="mb-4">
              <strong>Text:</strong>
              {!showFullBody ? (
                <ReactMarkdown
                  components={{}}
                  remarkPlugins={[]}
                  className="text-xs sm:text-sm"
                >
                  {compressedBody}
                </ReactMarkdown>
              ) : (
                <ReactMarkdown
                  components={{}}
                  remarkPlugins={[]}
                  className="text-xs sm:text-sm"
                >
                  {proposal.text}
                </ReactMarkdown>
              )}
              {proposal.text.length > 200 && (
                <button
                  onClick={() => setShowFullBody(!showFullBody)}
                  className="text-[#96fdbf] hover:text-[#3DFF54] bg-transparent mt-2 text-xs sm:text-sm"
                >
                  {showFullBody ? "Show Less" : "Read Full"}
                </button>
              )}
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="flex flex-col items-end justify-center w-full mt-16">
          <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
            {/* Text */}
            <p
              className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              © 2025 LobbyFi. All rights reserved.
            </p>
          </div>

          {/* Grid Component */}
          <div className="w-full  bg-black">
            <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
          </div>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default ProposalDetail;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import { useAccount } from "wagmi";
import { getChainId } from "@wagmi/core";
import { config } from "./config";
import { Link } from "react-router-dom";
import VectorGrid from "./assets/vector_grid.svg";
import { AiOutlineDown } from "react-icons/ai";

// Define the GraphQL query for fetching proposals from a specific space
const PROPOSALS_QUERY = gql`
  query Proposals(
    $type: Int
    $status: Int
    $network: Int
    $address: String
    $is_auction: Boolean
    $page: Int
    $limit: Int
  ) {
    proposals(
      type: $type
      status: $status
      network: $network
      address: $address
      is_auction: $is_auction
      page: $page
      limit: $limit
    ) {
      id
      type
      title
      status
      network
      address
      author
      trx_hash
      start_block
      end_block
      start_timestamp
      end_timestamp
      is_auction
      received_eth
      price_direct_buy
      voted_option
      options
      options_text
      text
    }
  }
`;

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", // http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

// const authLink = setContext((_, { headers }) => {
//   // Retrieve the API key from an environment variable or other secure storage
//   const token = process.env.REACT_APP_GRAPHQL_API_KEY;
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const ProposalIndex = () => {
  const currentChainId = getChainId(config);
  const [filterState, setFilterState] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterNetwork, setFilterNetwork] = useState("");
  const [filterAddress, setFilterAddress] = useState("");
  const [filterIsAuction, setFilterIsAuction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const [networkOpen, setNetworkOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [stateOpen, setStateOpen] = useState(false);

  const networkRef = useRef(null);
  const typeRef = useRef(null);
  const stateRef = useRef(null);

  const { isConnected } = useAccount();

  const typeMapping = {
    0: "snapshot",
    1: "on-chain",
  };

  const stateMapping = {
    0: "open",
    1: "closed",
  };

  const reverseStateMapping = {
    open: 0,
    closed: 1,
  };

  const networkMapping = {
    42161: "Arbitrum One",
    324: "zkSync",
    534352: "Scroll",
    10: "Optimism",
    // 169: "Manta Pacific Mainnet",
  };

  const reverseNetworkMapping = {
    "Arbitrum One": 42161,
    "Manta Pacific Mainnet": 169,
    "OP Mainnet": 10,
  };

  // useEffect(() => {
  //   if (isConnected) {
  //     setFilterNetwork(currentChainId);
  //   } else {
  //     setFilterNetwork(324);
  //   }
  // }, [currentChainId]);

  useEffect(() => {
    const chainNow = currentChainId;

    if (isConnected) {
      if (currentChainId) {
        setFilterNetwork(chainNow);
      } else {
        setFilterNetwork(42161); // Set to default chain
      }
    } else {
      setFilterNetwork(42161); // Set to default chain
    }
  }, [currentChainId, isConnected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (networkRef.current && !networkRef.current.contains(event.target)) {
        setNetworkOpen(false);
      }
      if (typeRef.current && !typeRef.current.contains(event.target)) {
        setTypeOpen(false);
      }
      if (stateRef.current && !stateRef.current.contains(event.target)) {
        setStateOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // const reverseStateMapping = Object.fromEntries(
  //   Object.entries(stateMapping).map(([key, value]) => [value, parseInt(key)])
  // );

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 20;

  // Adjust the useQuery hook to use the filter state as a variable
  const { loading, error, data, fetchMore } = useQuery(PROPOSALS_QUERY, {
    variables: {
      status: filterState !== "" ? filterState : null,
      type: filterType !== "" ? filterType : null,
      network: filterNetwork || null,
      address: filterAddress || null,
      is_auction: filterIsAuction || null,
      page: currentPage,
      limit: ITEMS_PER_PAGE,
    },
    client,
    notifyOnNetworkStatusChange: true,
  });

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  // console.log(data);
  // Pagination control handlers
  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchMore({
      variables: {
        page: currentPage + 1,
        limit: ITEMS_PER_PAGE,
      },
    });
  };

  if (loading)
    return (
      <div
        className="text-[#96fdbf] bg-black min-h-screen flex items-center justify-center"
        style={{
          fontFamily: "var(--body-text-font-family)",
          height: "100vh",
          width: "100%",
        }}
      >
        Loading...
      </div>
    );
  if (error)
    return (
      <div
        className="text-[#96fdbf] bg-black min-h-screen flex items-center justify-center"
        style={{
          fontFamily: "var(--body-text-font-family)",
          height: "100vh",
          width: "100%",
        }}
      >
        Error: {error.message}
      </div>
    );

  // Render the data in a simple table
  return (
    <ApolloProvider client={client}>
      <div
        className="relative bg-black min-h-screen text-[#96fdbf]"
        style={{ fontFamily: "var(--body-text-font-family)" }}
      >
        <div className="flex flex-col  items-center justify-between w-full">
          <Navbar />
        </div>
        <InfoBar />
        <div className="px-16 py-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
          <div className="flex flex-wrap gap-4">
            {/* Network Filter */}
            <div className="relative w-72" ref={networkRef}>
              <button
                onClick={() => setNetworkOpen(!networkOpen)}
                className="flex items-center justify-between w-full px-4 py-2.5 text-[#96fdbf] hover:text-[#3DFF54] bg-[#373737] hover:bg-gray-800 rounded transition-all border border-[#96fdbf]"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <span className="text-base">
                  {filterNetwork
                    ? networkMapping[filterNetwork]
                    : "All Networks"}
                </span>
                <AiOutlineDown
                  className={`ml-2 transition-transform duration-200 ${
                    networkOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {networkOpen && (
                <div className="absolute left-0 right-0 mt-2 bg-black border border-[#96fdbf] rounded-sm shadow-lg shadow-[#96fdbf]/10 z-50">
                  <div className="px-4 py-3 border-b border-[#373737]">
                    <span className="text-[#96fdbf] text-lg font-header">
                      Select Network
                    </span>
                  </div>
                  <div className="py-2">
                    <button
                      onClick={() => {
                        setFilterNetwork("");
                        setNetworkOpen(false);
                      }}
                      className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                    >
                      <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                        <span className="text-base">All Networks</span>
                      </div>
                    </button>
                    {Object.keys(networkMapping)
                      .sort((a, b) =>
                        networkMapping[a].localeCompare(networkMapping[b])
                      )
                      .map((network) => (
                        <button
                          key={network}
                          onClick={() => {
                            setFilterNetwork(parseInt(network));
                            setNetworkOpen(false);
                          }}
                          className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                        >
                          <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                            <span className="text-base">
                              {networkMapping[network]}
                            </span>
                          </div>
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>

            {/* Type Filter */}
            <div className="relative w-72" ref={typeRef}>
              <button
                onClick={() => setTypeOpen(!typeOpen)}
                className="flex items-center justify-between w-full px-4 py-2.5 text-[#96fdbf] hover:text-[#3DFF54] bg-[#373737] hover:bg-gray-800 rounded transition-all border border-[#96fdbf]"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <span className="text-base">
                  {filterType !== "" ? typeMapping[filterType] : "All Types"}
                </span>
                <AiOutlineDown
                  className={`ml-2 transition-transform duration-200 ${
                    typeOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {typeOpen && (
                <div className="absolute left-0 right-0 mt-2 bg-black border border-[#96fdbf] rounded-sm shadow-lg shadow-[#96fdbf]/10 z-50">
                  <div className="px-4 py-3 border-b border-[#373737]">
                    <span className="text-[#96fdbf] text-lg font-header">
                      Select Type
                    </span>
                  </div>
                  <div className="py-2">
                    <button
                      onClick={() => {
                        setFilterType("");
                        setTypeOpen(false);
                      }}
                      className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                    >
                      <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                        <span className="text-base">All Types</span>
                      </div>
                    </button>
                    {Object.keys(typeMapping).map((type) => (
                      <button
                        key={type}
                        onClick={() => {
                          setFilterType(parseInt(type));
                          setTypeOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                      >
                        <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                          <span className="text-base">{typeMapping[type]}</span>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* State Filter */}
            <div className="relative w-72" ref={stateRef}>
              <button
                onClick={() => setStateOpen(!stateOpen)}
                className="flex items-center justify-between w-full px-4 py-2.5 text-[#96fdbf] hover:text-[#3DFF54] bg-[#373737] hover:bg-gray-800 rounded transition-all border border-[#96fdbf]"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <span className="text-base">
                  {filterState !== ""
                    ? stateMapping[filterState]
                    : "All States"}
                </span>
                <AiOutlineDown
                  className={`ml-2 transition-transform duration-200 ${
                    stateOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {stateOpen && (
                <div className="absolute left-0 right-0 mt-2 bg-black border border-[#96fdbf] rounded-sm shadow-lg shadow-[#96fdbf]/10 z-50">
                  <div className="px-4 py-3 border-b border-[#373737]">
                    <span className="text-[#96fdbf] text-lg font-header">
                      Select State
                    </span>
                  </div>
                  <div className="py-2">
                    <button
                      onClick={() => {
                        setFilterState("");
                        setStateOpen(false);
                      }}
                      className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                    >
                      <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                        <span className="text-base">All States</span>
                      </div>
                    </button>
                    {Object.keys(stateMapping).map((state) => (
                      <button
                        key={state}
                        onClick={() => {
                          setFilterState(parseInt(state));
                          setStateOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                      >
                        <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                          <span className="text-base">
                            {stateMapping[state]}
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="relative sm:px-8 md:px-16 lg:px-32 xl:px-64">
          {/* Table with dashed borders */}
          <table className="table-auto w-full border-collapse border-2 border-dashed border-[#96fdbf] text-sm sm:text-base mt-4">
            <thead>
              <tr className="border-b-2 border-dashed border-[#96fdbf]">
                <th className="font-bold p-2 border-r-2 border-dashed border-[#96fdbf]">
                  Title
                </th>
                <th className="font-bold p-2 border-r-2 border-dashed border-[#96fdbf]">
                  Status
                </th>
                <th className="font-bold p-2 border-r-2 border-dashed border-[#96fdbf]">
                  Deadline
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.proposals.map(({ id, title, end_timestamp, status }) => (
                  <tr
                    key={id}
                    className="border-b-2 border-dashed border-[#96fdbf] cursor-pointer hover:text-[#3DFF54] hover:bg-gray-800"
                    onClick={() => navigate(`/proposals/${id}`)}
                  >
                    <td className="py-4 p-2 border-r-2 border-dashed border-[#96fdbf]">
                      {title}
                    </td>
                    <td
                      className={`text-center py-4 p-2 border-r-2 border-dashed border-[#96fdbf] ${
                        status === 0
                          ? "bg-green-500"
                          : status === 1
                          ? "bg-rose-500"
                          : ""
                      }`}
                    >
                      {stateMapping[status]}
                    </td>
                    <td className="text-center py-4 p-2 border-r-2 border-dashed border-[#96fdbf]">
                      {end_timestamp
                        ? new Date((end_timestamp - 36000) * 1000).toUTCString()
                        : ""}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="mt-4">
            <button
              className="bg-transparent border-2 border-[#96fdbf] mr-2 p-1 hover:text-[#3DFF54] disabled:opacity-50"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="bg-transparent border-2 border-[#96fdbf] p-1 hover:text-[#3DFF54] disabled:opacity-50"
              onClick={goToNextPage}
              disabled={data && data.proposals.length < ITEMS_PER_PAGE}
            >
              Next
            </button>
          </div>
        </div>
        {/* Footer */}
        <div className="flex flex-col items-end justify-center w-full mt-16">
          <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
            {/* Text */}
            <p
              className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              © 2025 LobbyFi. All rights reserved.
            </p>
          </div>

          {/* Grid Component */}
          <div className="w-full  bg-black">
            <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
          </div>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default ProposalIndex;
